import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolicitudInterceptor } from '../interceptores/solicitud.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Utilidades } from '../libs/utilidades';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class GlobaldataService { 
	public clientName = new BehaviorSubject("");
	clientNameObservable = this.clientName.asObservable();
	public clientDetails = new BehaviorSubject("");
	clientDetailsObservable = this.clientDetails.asObservable();
	public subCompany = new BehaviorSubject([]);
	subCompanyObservable = this.subCompany.asObservable();

	public angularProjectVersion: String;

	public urlBase: String;//="http://localhost:3000/";
	//public urlBase:String="https://portalasesoria.oficinadigital.eu/";


	public urlBaseDL: String;//=this.urlBase+"apidl/";
	public urlBaseBL: String;//=this.urlBase+"apibl/";
	//public urlBaseDL:String="https://oficinadigital.eu:8443/serviceODE/";
	//public urlBaseDL:String="https://extranet.oficinadigital.eu/serviceODE/";
	private p_users: Promise<Object[]> = null;
	private p_materias: Promise<Object[]> = null;
	private cached_materias: Object[] = null;
	private p_clientes: Promise<Object[]> = null;
	private p_ejercicios: Promise<Object[]> = null;
	private p_login: Promise<String> = null;

	public id_cliente: Number = 0;
	public pwd_cliente: String = "";
	public name_cliente: String = "";
	public idClient_collaborator: Number = 0;
	public controlHorario: Boolean = false;
	public portalEmpleados: Boolean = false;
	public clientIdAll:any ="";
	public collaborator_cif:any ="";
	public collaborator_apikey:any ="";


	public access_documents_recent:any ="";
    public access_documents_senddocument:any ="";
    public access_documents_mydocument:any ="";
    public access_documents_taxes:any ="";
    public access_consultation:any ="";
    public access_issues:any ="";
    public access_information:any ="";
    public access_labor_incidences:any ="";
    public access_labor_names:any ="";
    public access_labor_payrollsummery:any ="";
    public access_labor_docemployees:any ="";
    public access_labor_paidgrandchildren:any ="";
    public access_labor_employeeportal:any ="";
    public access_labor_request:any ="";
	public access_labor_entryexit_request:any ="";
    public access_labor_time:any ="";
    public access_labor_checkpermissions:any ="";
    public access_labor_globalquery:any ="";
    public access_labor_complaintsportal:any ="";
    public access_mydata:any ="";
    public access_collaborator:any ="";
	public access_mylatest_documents:any ="";
	public access_mylatest_affairs:any ="";




	public ejercicios = [];
	public ejercicio_actual: number;
	public materias = [];
	public subMaterias = [];
	public meses: {} = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((mes, i) => {
		if (i < 9) return { label: mes, value: '0' + (i + 1) }; else return { label: mes, value: '' + (i + 1) };
	});
	public mes_actual: string;
	private empleados: [];

	


	constructor(private httpClient: HttpClient) {
		console.log(environment.production);
		console.log(environment.urlBase);
		this.angularProjectVersion = "Versión 03.09.2024";
		this.urlBase = environment.urlBase;
		this.urlBaseDL = this.urlBase + "apidl/";
		this.urlBaseBL = this.urlBase + "apibl/";
		this.mes_actual = '' + ((new Date()).getMonth() + 1);
		if (this.mes_actual.length == 1) this.mes_actual = '0' + this.mes_actual;
		
	}

	// ===========================================================
	//                       Metodos API Rest
	// ===========================================================
	_get_users(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'usuarios').toPromise();
	}

	_get_materias(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'materias?fields=[idPadre,idMateria,materia]').toPromise();
	}

	_get_ejercicios(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'documentos/ejercicios').toPromise();
	}

	_get_clientes(): Promise<Object[]> {
		return this.httpClient.get<Object[]>(this.urlBaseDL + 'clientes').toPromise();
	}

	init() {
		this.httpClient.post(this.urlBaseDL + 'multiple',
			[
				{
					"method": "get", "url": "materias", "params": {
						"order": "materia",
						"fields": '[idPadre,idMateria,materia]',
						"where": "idMateria!=200"
					}
				},

				{
					"method": "get", "url": "ejercicios", "params": {
						"order": "ejercicio DESC",
						"fields": '[ejercicio]'
					}
				},

				{
					"method": "get", "url": "empleado", "params": {
						order: "T.empleado",
						joins: "trabajador",
						fields: '[E.idEmpleado,T.empleado]',
						where: "E.baja=0 and E.idCliente=" + this.id_cliente
					}
				}
			]
		).subscribe((res) => {
			console.log(res);

			this.subMaterias = res[0]['resp'];
			this.materias = this.subMaterias.filter(mat => mat[0] === 0).map(mat => {
				return { label: mat[2], value: mat[1] };
			});

			this.ejercicios = res[1]['resp'].map(ej => { return { label: String(ej), value: ej } });
			this.ejercicio_actual = res[1]['resp'][0];

			this.empleados = res[2]['resp'].map((emp) => { return { value: emp[0], label: emp[1] } });

		});
	}

	_login(user: number, pass: string): Promise<Object> {
		const payload = new HttpParams()
			.set('username', String(user))
			.set('password', pass);

		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login', payload).toPromise();
	}

	_login2(user: number, pass: string): Promise<Object> {
		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login2',
			{
				username: user,
				password: pass
			}).toPromise();
	}

	_login3(user: number, pass: string): Promise<Object> {
		return this.httpClient.post<Object>(this.urlBaseDL + 'usuarios/login3',
			{
				username: user,
				password: pass
			}).toPromise();
	}

	async makeLoginSync(user: number, pass: string): Promise<Object> {
		const obj = <Object>await this._login2(user, pass);
		return obj;
	}

	async loginCliente(user: string, pass: string,idCliente: string): Promise<Boolean> {
		var out: Boolean = false;



		const payload = new HttpParams()
			.set('cif', user)
			.set('password', pass)
			.set('idCliente', idCliente);

		await this.httpClient.post<Object>(this.urlBaseDL + 'clientes/login', payload).toPromise().then((res: any) => {
			res = res['resp'];
			console.log(res);
			this.clientName.next(res.cliente);
			this.clientDetails.next(res);
			this.getSubcomapnies(res.clientSubCompaniesId).subscribe((data: any) => {
				console.log("sub comanies...", data.data)
				this.subCompany.next(data.data);
			});
			if (res['token'].length > 0) {
				console.log("LOGADO");
				this.id_cliente = res['idCliente'];
				this.controlHorario = res['controlhorario'];
				this.portalEmpleados = res['portalempleados'];
				this.pwd_cliente = pass;
				this.name_cliente = res['cliente'];
				this.idClient_collaborator = res['idClient_collaborator'];
				this.clientIdAll = res['clientIdAll'];
				this.collaborator_cif = res['collaborator_cif'];
				this.collaborator_apikey = res['collaborator_apikey'];

				this.access_documents_recent = res['access_documents_recent'];
				this.access_documents_senddocument = res['access_documents_senddocument'];
				this.access_documents_mydocument = res['access_documents_mydocument'];
				this.access_documents_taxes = res['access_documents_taxes'];
				this.access_consultation = res['access_consultation'];
				this.access_issues = res['access_issues'];
				this.access_information = res['access_information'];
				this.access_labor_incidences = res['access_labor_incidences'];
				this.access_labor_names = res['access_labor_names'];
				this.access_labor_payrollsummery = res['access_labor_payrollsummery'];
				this.access_labor_docemployees = res['access_labor_docemployees'];
				this.access_labor_paidgrandchildren = res['access_labor_paidgrandchildren'];
				this.access_labor_employeeportal = res['access_labor_employeeportal'];
				this.access_labor_request = res['access_labor_request'];
				this.access_labor_entryexit_request = res['access_labor_entryexit_request'];
				this.access_labor_time = res['access_labor_time'];
				this.access_labor_checkpermissions = res['access_labor_checkpermissions'];
				this.access_labor_globalquery = res['access_labor_globalquery'];
				this.access_labor_complaintsportal = res['access_labor_complaintsportal'];
				this.access_mydata = res['access_mydata'];
				this.access_collaborator = res['access_collaborator'];
				this.access_mylatest_documents = res['access_mylatest_documents'];
				this.access_mylatest_affairs = res['access_mylatest_affairs'];


				SolicitudInterceptor.token = res['token'];
				// try{
				// 	let restore = localStorage.getItem('token');
				// 	let restore2 = sessionStorage.getItem('token');
				// 	console.log(restore);
				// 	localStorage.setItem('token','********');
				// }catch(e){console.log(e);}
				out = true;
			}
			else {
				console.log("ERROR");
				out = false;
			}
		});

		return out;

	}

	async logoutCliente() {

		return this.httpClient.get(this.urlBaseDL + 'logout', { responseType: 'text' }).subscribe((res) => {
			console.log(res);
			this.id_cliente = 0;
			this.pwd_cliente = "";
			SolicitudInterceptor.token = '';
		});

		/* NO BORRAR - ES UN EJEMPLO DE POST CON RESPUESTA TEXT */
		/*return this.httpClient.post(this.urlBaseDL+'logout', null,{responseType: 'text'}).subscribe((res)=>{
			console.log(res);
			this.id_cliente=0;
			this.pwd_cliente="";
			SolicitudInterceptor.token='';
		});*/
	}


	// ===========================================================
	//                       Metodos CACHEADOS
	// ===========================================================
	getEmpleados() {
		return this.empleados;
	}


	get_users() {
		if (!this.p_users) this.p_users = this._get_users();
		return this.p_users;
	}

	get_materias() {
		if (!this.p_materias) this.p_materias = this._get_materias();
		return this.p_materias; //.catch(res=>{console.log(res.status);});
	}

	/*async get_sync_materias()
	{
		if(this.cached_materias==null)
		{
			this.cached_materias=await this._get_materias();
			//promesa.then((res)=>{this.cached_materias=res;});
		}
		return this.cached_materias;
	}*/

	get_clientes() {
		if (!this.p_clientes) this.p_clientes = this._get_clientes();
		return this.p_clientes;
	}

	get_ejercicios() {
		if (!this.p_ejercicios) this.p_ejercicios = this._get_ejercicios();
		return this.p_ejercicios;
	}

	// =================================================
	// 		EJEMPLOS DE RECEPCION DE DATOS!!!!!!!!
	// =================================================

	/*
	
	_login3():Promise<Datalogin>   //Convertido a Promise<Datalogin> directamente.
	{
		return this.httpClient.post<Datalogin>('http://localhost:8080/serviceODE/usuarios/prueba',
		{
			username: 100, 
			password: 'Java Functional Interface'
		}).toPromise();
	}
	
	let dl:Datalogin=new Datalogin();
		dl.username="Pepe";
		dl.password="12345";	
		this.httpClient.post('http://localhost:8080/serviceExtranet/usuarios/login',dl).subscribe((res)=>{
		   console.log(res);
		   //console.log(res.token);
		   console.log(res['token']);
		});
	*/

	public getID(ev) {
		var id = 0;
		if (ev.target.hasAttribute("data-id"))
			id = ev.target.attributes['data-id'].value;
		else
			if (ev.target.parentElement.hasAttribute("data-id"))
				id = ev.target.parentElement.attributes['data-id'].value;
			else
				id = 0;
		return id;
	}

	public viewIDDoc(id) {
		console.log(id);
		//alert("sss");
		if (id > 0) {
			//window.open(this.urlBaseDL+'files/'+id+'?ta='+SolicitudInterceptor.token);
			var params = {
				id: id,
				ta: SolicitudInterceptor.token
			};
			window.open(this.urlBaseDL + 'files/' + Utilidades.packVLO(params));
		}
		return false;
	}

	public viewIDDocNew(id) {

		//alert(id);
		console.log(id);

		if (id > 0) {
			//window.open(this.urlBaseDL+'files/'+id+'?ta='+SolicitudInterceptor.token);
			var params = {
				id: id,
				ta: SolicitudInterceptor.token
			};
			//alert(this.urlBaseDL + 'solfiles/' + Utilidades.packVLO(params));
			window.open(this.urlBaseDL + 'solfiles/' + Utilidades.packVLO(params));
		}

		return false;
	}

	public viewDoc(ev) {
		console.log(ev);
		var id = this.getID(ev);
		this.viewIDDoc(id);
		return false;
	}

	public viewTreeDoc(event) { 
		console.log(event.node);
		if (!('children' in event.node)) {
			this.viewIDDoc(event.node.key);
		}
		return false;
	}

	updateSolicitud(id, nuevo): Promise<any> {
		return this.httpClient.put(this.urlBaseDL + 'solicitudes/' + id, nuevo).toPromise();
	}

	emailToEmpleado(id, subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtoemp/' + id, { email: { subject: subject, body: body } }).toPromise(); 
	}

	emailToLaboral(subject, body): Promise<any> {
		return this.httpClient.post(this.urlBaseBL + 'emailtolaboral', { email: { subject: subject, body: body } }).toPromise();
	}
	getSubcomapnies(id) {
		return this.httpClient.get(this.urlBaseDL + `client/subcompany/${id}`);

	}
	//http://localhost:3000/apidl/empleado/mispunchList/202/2022-11-15
	getMisspunchEmployees(clientId,date) {
		return this.httpClient.get(this.urlBaseDL + `empleado/mispunchList/${clientId}/${date}`);

	}
	misspunchUpdateEmployees(idhorario,data) {
		return this.httpClient.post(this.urlBaseDL + `empleado/mispunchListUpdate/${idhorario}`,data);
	}
	resetPassword(nif){
		return this.httpClient.post(`${this.urlBaseDL}client/resetpassword/${nif}`,{});

	}
	downloadLeaveReport(data){
		return this.httpClient.post(`${this.urlBaseDL}/client/download/report`,data);

	}

	downloadLeaveReportEmp(data){
		return this.httpClient.post(`${this.urlBaseDL}/client/downloademp/report`,data);

	}


	downloadLeaveReportGlobal(data){
		return this.httpClient.post(`${this.urlBaseDL}/client/downloadglobal/report`,data);

	}




	
	updateSolicitudCada(id, nuevo): Promise<any> {
		return this.httpClient.put(this.urlBaseDL + 'solicitudescada/' + id, nuevo).toPromise();
	}

	updateSolicitudNew(id, nuevo): Promise<any> {
		return this.httpClient.put(this.urlBaseDL + 'solicitudes/' + id, nuevo).toPromise();
	}







}
