<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Consulta de nóminas de empleados</p>

  
    
    <div class="grid filtro r-grid r-grid12-12-12-6">
    <!--<fieldset class="g-w4 r-grid">
    <legend>Filtro de nóminas</legend>-->

        <div class="g-w6field">
            <small>Ejercicio:</small>
            <p-dropdown [autoDisplayFirst]="true" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="loadNominas();"></p-dropdown>
        </div>
        <div class="g-w6field">
            <small>Mes:</small>
            <p-dropdown  [autoDisplayFirst]="true" [showClear]="false" [options]="gds.meses" [(ngModel)]="mes" (onChange)="loadNominas();"></p-dropdown>
        </div>
        
    <!--</fieldset>-->
    </div>
    


    

<p-table [value]="nominas" (click)="click_tabla($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>Empleado</th>
            <th>Importe</th>
            <th>Documentos</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-nomina>
        <tr>
            <td>{{nomina.empleado}}</td>
            <td>{{nomina.importe| number:'1.2':'es'}}€</td>
            <td><i [attr.data-id]="doc[0]" class="fa fa-file icono enlace" *ngFor="let doc of nomina.nominas"></i></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        Importe total: {{total| number:'1.2'}}€
    </ng-template>
</p-table>

